<template>
  <div id="app" class="d-flex flex-column h-100 bg-black">
    <div
      class="
        text-white
        py-2
        w-full
        text-center
        bg-gradient-to-r
        border-none
        from-indigo
        to-barbiePink
        animate-text
      "
    >
      2D to 3D is moving to Blocks,
      <strong>
        <a
          href="https://lookingglassfactory.com/blog/introducing-image-to-hologram-on-blocks"
        >
          ✨ Learn more ✨</a
        ></strong
      >
    </div>
    <nav-bar />
    <div class="container flex-grow-1 px-0">
      <!-- <error /> -->
      <div class="mt-5">
        <router-view />
      </div>
    </div>
    <footer class="bg-light text-center p-3 bg-black">
      <Container size="full">
        <div class="grid gap-4 py-4">
          <nav
            class="
              grid
              gap-4
              text-center
              justify-center
              md:grid-flow-col
              md:justify-between
            "
          >
            <div class="flex space-x-6 w-full justify-center">
              <a
                href="https://lookingglassfactory.com/contact"
                target="_blank"
                class="text-md text-white"
                to="/contact"
                >Contact</a
              >
              <a
                href="https://lookingglassfactory.com/product/overview"
                target="_blank"
                class="text-md text-white"
                >Products</a
              >
            </div>
            <div class="flex space-x-6 w-full justify-center">
              <a
                href="https://discordapp.com/invite/ZW87Y4m"
                target="_blank"
                rel="noopener"
              >
                <svg
                  width="23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 25"
                >
                  <path
                    d="M23.05 24.453l-5.606-4.2.623 1.8H3.115c-1.72 0-3.115-1.343-3.115-3v-15.6c0-1.657 1.395-3 3.115-3h16.82c1.722 0 3.116 1.343 3.116 3v21z"
                    fill="#120740"
                  />
                  <path
                    d="M16.86 7.822s-1.526-1.228-3.333-1.369l-.159.336c1.631.41 2.377 1.002 3.157 1.73-1.344-.715-2.675-1.455-5-1.455-2.323 0-3.654.74-4.998 1.455.78-.728 1.672-1.388 3.157-1.73l-.159-.336c-1.895.19-3.333 1.369-3.333 1.369s-1.707 2.56-2 7.566c1.725 2.053 4.336 2.065 4.336 2.065l.54-.752a6.635 6.635 0 01-2.876-1.998c1.08.844 2.705 1.528 5.334 1.528 2.629 0 4.254-.685 5.333-1.528a6.634 6.634 0 01-2.875 1.998l.54.752s2.611-.012 4.336-2.065c-.293-5.005-2-7.566-2-7.566zm-7.388 6.576c-.645 0-1.173-.685-1.173-1.528s.528-1.528 1.173-1.528c.646 0 1.174.684 1.174 1.528 0 .843-.528 1.528-1.174 1.528zm4.108 0c-.646 0-1.174-.685-1.174-1.528s.528-1.528 1.174-1.528c.645 0 1.173.684 1.173 1.528 0 .843-.528 1.528-1.173 1.528z"
                    fill="#FFFFFF"
                  />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/lookingglassfactory/"
                rel="noopener"
                target="_blank"
              >
                <svg
                  width="23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 23"
                >
                  <path
                    d="M21.152.938H2.292L.198 2.873v17.429l2.096 1.936H12.77v-8.714H9.627V10.62h3.143V8.684c0-2.905 1.048-3.873 4.191-3.873h2.096v2.904H16.96l-1.047 1.937v.968h3.143v2.905h-3.143v8.714h5.238l2.096-1.936V2.874L21.152.937z"
                    fill="#FFFFFF"
                  />
                </svg>
              </a>
              <a
                href="https://twitter.com/lkgglass"
                rel="noopener"
                target="_blank"
              >
                <svg
                  width="27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 28 23"
                >
                  <path
                    d="M27.636 3.841l-3.143.969 2.095-2.905-3.143.968C22.75 1.718 21.587.864 20.212.501a5.788 5.788 0 00-4.102.436c-1.25.642-2.173 1.716-2.566 2.987a4.65 4.65 0 00.47 3.79c-5.238 0-9.429-1.936-11.524-5.809a4.825 4.825 0 00-1.332 1.883A4.498 4.498 0 00.89 6.023c.103.754.395 1.476.855 2.106A5.108 5.108 0 003.537 9.65l-2.095-.969c0 2.905 2.095 4.841 4.19 4.841-.69.13-1.403.13-2.095 0 1.048 1.937 3.144 3.873 5.24 3.873-2.417 1.626-5.423 2.32-8.383 1.937 2.096 1.936 5.239 2.904 8.382 2.904 2.155.005 4.288-.4 6.267-1.19a15.78 15.78 0 005.234-3.4 14.376 14.376 0 003.334-5.045 13.51 13.51 0 00.881-5.856l3.144-2.905z"
                    fill="#FFFFFF"
                  />
                </svg>
              </a>
              <a
                href="https://instagram.com/lookingglassfactory"
                target="_blank"
                rel="noopener"
              >
                <svg
                  width="25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 26 24"
                >
                  <path
                    d="M8.116 0C3.926 0 .782 2.905.782 6.778v9.683c0 3.873 3.143 6.778 7.334 6.778h10.478c4.19 0 7.334-2.905 7.334-6.778V6.778C25.928 2.905 22.785 0 18.594 0H8.116zM20.69 3.873c.278 0 .545.102.741.284a.933.933 0 01.307.685c0 .256-.11.503-.307.684a1.093 1.093 0 01-.74.284c-.279 0-.545-.102-.742-.284a.933.933 0 01-.306-.684c0-.257.11-.504.306-.685.197-.182.463-.284.741-.284zM13.355 5.81c1.667 0 3.266.612 4.445 1.701 1.18 1.09 1.842 2.568 1.842 4.109 0 1.54-.663 3.018-1.842 4.108-1.179 1.09-2.778 1.701-4.445 1.701-1.667 0-3.266-.612-4.445-1.701-1.18-1.09-1.841-2.567-1.841-4.108 0-1.541.662-3.019 1.84-4.109 1.18-1.09 2.779-1.701 4.446-1.701zm0 1.936c-1.112 0-2.178.408-2.963 1.135-.786.726-1.228 1.711-1.228 2.739 0 1.027.442 2.012 1.228 2.738.786.727 1.851 1.135 2.963 1.135s2.178-.408 2.964-1.135c.786-.726 1.227-1.711 1.227-2.738 0-1.028-.441-2.013-1.227-2.74-.786-.726-1.852-1.134-2.964-1.134z"
                    fill="#FFFFFF"
                  />
                </svg>
              </a>
            </div>
          </nav>
          <div
            class="
              grid
              text-sm
              justify-center
              gap-4
              md:grid-flow-col
              auto-col-auto
              justify-between
            "
          >
            <div
              class="
                grid
                gap-4
                text-center
                md:text-left
                md:grid-flow-col
                auto-col-min
                justify-between
              "
            >
              <p class="md:w-44 text-white">
                © Looking Glass Factory, Inc. All rights reserved.
              </p>
              <p class="md:w-96 text-white">
                The technology behind the Looking Glass hardware and software is
                protected by patents granted and pending around the world.
              </p>
            </div>
            <div class="text-center md:text-right text-white">
              <p>
                <a href="https://lookingglassfactory.com/terms"
                  >Terms & Conditions</a
                >
              </p>
              <p>
                <a href="https://lookingglassfactory.com/privacy"
                  >Privacy Policy</a
                >
              </p>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
// import Error from "./components/Error";
import Container from "../node_modules/holo-design-system-vue/src/components/Container.vue";

export default {
  components: {
    NavBar,
    // Error,
    Container,
  },
};
</script>

<style src="./assets/tailwind.css" />
